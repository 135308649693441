<template>
    <ComboboxOption :value="option" v-slot="{ active, selected }">
        <li
            :class="[
                'relative cursor-default select-none py-2 pl-3 pr-9 focus:ring-0',
                active
                    ? 'bg-blue-600 text-white outline-none dark:bg-blue-700 dark:text-gray-200'
                    : 'text-gray-900 dark:text-gray-200',
            ]"
            :data-cy="`${config.field}-option-${index}`"
        >
            <span :class="['block truncate', selected && 'font-semibold']">
                {{ option[config.label] }}
            </span>

            <span
                v-if="selected"
                :class="[
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                    active ? 'text-white' : 'text-blue-600',
                ]"
            >
                <CheckIcon class="size-5" aria-hidden="true" />
            </span>
        </li>
    </ComboboxOption>
</template>

<script setup>
import { inject } from "vue";
import { ComboboxOption } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
    index: {
        type: [Number, String],
        required: true,
    },
    option: {
        type: Object,
        required: true,
    },
});

const config = inject("config");
</script>
