<template>
    <Head title="Home"></Head>

    <Content>
        <template #header>
            <Header> Home </Header>
        </template>
        <p class="px-3 text-2xl text-gray-900 sm:px-0 dark:text-gray-200">
            Hello, {{ user.name }}!
        </p>

        <template v-if="!verification.profileVerified">
            <TheProfileVerificationBanner
                :returning="verification.returning"
                :link="verification.profile"
            />
        </template>

        <div
            class="mt-6 block space-y-2 sm:grid sm:grid-cols-3 sm:gap-x-4 sm:space-y-0"
        >
            <slot name="tiles" />
        </div>
    </Content>
</template>

<script setup>
import { inject } from "vue";
import Header from "@/Shared/Layouts/Stacked/Header.vue";
import Content from "@/Shared/Layouts/Stacked/Content.vue";
import { useCurrentUser } from "@/Composables/useCurrentUser.js";
import TheProfileVerificationBanner from "@/Shared/Dashboard/TheProfileVerificationBanner.vue";

const user = useCurrentUser();

const verification = inject("verification");
</script>
