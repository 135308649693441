<template>
    <tr class="border-b border-gray-100 dark:border-gray-700">
        <td class="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
            <div
                class="flex items-center font-medium text-gray-900 dark:text-gray-200"
            >
                {{ entry.date }}
                <template v-if="entry.generated_by !== 'system'">
                    <span
                        class="ml-1 text-gray-600 transition duration-150 ease-in-out hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                        v-tippy:generated
                    >
                        <component
                            :is="rowIcons[entry.generated_by].icon"
                            class="size-4"
                        />
                    </span>
                    <Tippy target="generated" :extra="tippyConfig">
                        <div
                            class="rounded-sm border border-gray-400 bg-white p-2 dark:border-gray-600 dark:bg-gray-800"
                        >
                            <p class="text-sm text-gray-900 dark:text-gray-200">
                                {{ rowIcons[entry.generated_by].title }}
                            </p>
                        </div>
                    </Tippy>
                </template>
            </div>
            <div class="mt-0.5 text-gray-500 md:hidden dark:text-gray-400">
                <p class="font-medium text-gray-900 dark:text-gray-200">
                    {{ entry.role.name }}
                </p>
                <p>{{ entry.site.name }}</p>
            </div>
            <div class="mt-0.5 text-gray-500 sm:hidden dark:text-gray-400">
                <span class="flex">
                    <span v-if="entry.hourly" class="mr-2"
                        >{{ hoursDisplay }} hr
                    </span>
                    <template v-if="entry.hourly && editable">
                        <button
                            type="button"
                            class="st-link ml-1"
                            title="Edit Hours"
                            @click="onEditHours"
                        >
                            <PencilIcon class="size-4" />
                        </button>
                    </template>
                    {{ entry.rate.amount }}
                </span>
                <span class="flex">
                    <template v-if="entry.travelEditButton">
                        <template v-if="travelSet">{{ entry.travel }}</template>
                        <template v-else>
                            <div class="inline-flex italic">
                                <ExclamationTriangleIcon
                                    class="mr-1 size-5 text-yellow-600 dark:text-yellow-500"
                                    title="Travel method has not been set"
                                />
                                Not Set
                            </div>
                        </template>
                        <template v-if="editable">
                            <button
                                @click="onEditTravel"
                                type="button"
                                class="st-link ml-1"
                                title="Edit Travel Arrangements"
                            >
                                <PencilIcon class="size-4" />
                            </button>
                        </template>
                    </template>
                </span>
            </div>
        </td>
        <td
            class="hidden px-3 py-4 text-left text-sm text-gray-500 md:table-cell dark:text-gray-400"
        >
            <p class="font-medium">{{ entry.role.name }}</p>
            <p>{{ entry.site.name }}</p>
        </td>
        <td
            class="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell dark:text-gray-400"
        >
            <span class="flex justify-end">
                <template v-if="entry.hourly">
                    <span>{{ hoursDisplay }} hr </span>
                    <template v-if="editable">
                        <button
                            type="button"
                            class="st-link ml-1"
                            title="Edit Hours"
                            @click="onEditHours"
                        >
                            <PencilIcon class="size-4" />
                        </button>
                    </template>
                </template>
            </span>
            {{ entry.rate.amount }}
        </td>
        <td
            class="px-3 py-4 text-right text-sm text-gray-500 sm:table-cell dark:text-gray-400"
        >
            {{ entry.role_subtotal }}
        </td>
        <td
            class="px-3 py-4 text-right text-sm text-gray-500 sm:table-cell dark:text-gray-400"
        >
            <div
                class="flex flex-row-reverse items-center justify-end sm:flex-row"
            >
                {{ entry.other_subtotal }}
                <template v-if="entry.other_subtotal !== '$0.00'" as="span">
                    <div class="mr-2 sm:-mr-6 sm:ml-2" v-tippy:html>
                        <InformationCircleIcon class="st-link size-4" />
                    </div>
                    <Tippy target="html" :extra="tippyConfig">
                        <div
                            class="rounded-sm border border-gray-400 bg-white p-2 dark:border-gray-600 dark:bg-gray-800"
                        >
                            <ul
                                class="text-sm text-gray-900 dark:text-gray-200"
                            >
                                <li v-for="item of otherSummary">{{ item }}</li>
                            </ul>
                        </div>
                    </Tippy>
                </template>
            </div>
        </td>
        <td
            class="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell dark:text-gray-400"
        >
            <span class="flex justify-end">
                <template v-if="travelSet">{{ entry.travel }}</template>
                <template v-else>
                    <div class="inline-flex italic">
                        <ExclamationTriangleIcon
                            class="mr-1 size-5 text-yellow-600 dark:text-yellow-500"
                            title="Travel method has not been set"
                        />
                        Not Set
                    </div>
                </template>
                <template v-if="entry.travelEditButton && editable">
                    <button
                        @click="onEditTravel"
                        type="button"
                        class="st-link ml-1"
                        title="Edit Travel Arrangements"
                    >
                        <PencilIcon class="size-4" />
                    </button>
                </template>
            </span>
        </td>
        <td
            class="hidden py-4 pl-3 pr-4 text-right text-sm font-medium text-gray-900 sm:table-cell sm:pr-6 md:pr-0 dark:text-gray-200"
        >
            {{ entry.total }}
        </td>
        <td>
            <template v-if="editable">
                <OptionsMenuButton class="px-2">
                    <OptionSection
                        v-if="entry.travelEditButton || entry.hourly"
                    >
                        <OptionMenuOption
                            v-if="entry.hourly"
                            :icon="ClockIcon"
                            @click="onEditHours"
                            >Edit Hours</OptionMenuOption
                        >
                        <OptionMenuOption
                            v-if="entry.travelEditButton"
                            :icon="TruckIcon"
                            @click="onEditTravel"
                            >Edit Travel</OptionMenuOption
                        >
                    </OptionSection>
                    <OptionSection v-if="admin">
                        <OptionMenuOption
                            :icon="CurrencyDollarIcon"
                            @click="onMakeAdjustment"
                            >Adjust Row</OptionMenuOption
                        >
                    </OptionSection>
                    <OptionSection>
                        <OptionMenuOption
                            :icon="XMarkIcon"
                            @click="removeRow"
                            color="red"
                            >Remove Row</OptionMenuOption
                        >
                    </OptionSection>
                </OptionsMenuButton>
            </template>
        </td>
    </tr>
    <tr class="mt-1">
        <td
            class="py-4 pl-4 pr-3 text-sm font-medium text-gray-800 sm:hidden sm:pl-6 md:pl-0 dark:text-gray-300"
        >
            Line Total
        </td>
        <td
            colspan="3"
            class="py-4 pl-3 pr-4 text-right text-sm font-medium text-gray-800 sm:hidden sm:pr-6 md:pr-0 dark:text-gray-300"
        >
            {{ entry.total }}
        </td>
    </tr>
</template>

<script setup>
import { computed } from "vue";
import {
    ClockIcon,
    CurrencyDollarIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    PencilIcon,
    StarIcon,
    TruckIcon,
    UserIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/vue/20/solid";
import { titleCase } from "@/Modules/mutators.js";
import { Tippy, TippyDirective } from "tippy.vue";
import OptionsMenuButton from "@/Shared/Buttons/OptionsMenu/OptionsMenuButton.vue";
import OptionSection from "@/Shared/Buttons/OptionsMenu/OptionSection.vue";
import { MenuItem } from "@headlessui/vue";
import OptionMenuOption from "@/Shared/Buttons/OptionsMenu/OptionMenuOption.vue";

const props = defineProps({
    entry: Object,
    editable: {
        type: Boolean,
        default: false,
    },
    admin: {
        type: Boolean,
        default: false,
    },
});

const vTippy = TippyDirective;

const emit = defineEmits([
    "editTravelClicked",
    "editHoursClicked",
    "rowAdjustClicked",
    "rowRemoved",
]);

const tippyConfig = {
    allowHTML: true,
    animation: "shift-away-extreme",
    delay: [1000, 250],
    trigger: "mouseenter focus click",
    role: "tooltip",
    placement: "top",
    onTrigger(instance, event) {
        if (event.type === "click") {
            instance.show();
            setTimeout(() => instance.hide(), 2000);
        }
    },
};

const onEditTravel = () => emit("editTravelClicked", props.entry);
const onEditHours = () => emit("editHoursClicked", props.entry);
const onMakeAdjustment = () => emit("rowAdjustClicked", props.entry);

const otherSummary = computed(() => {
    let summary = [];

    for (const property in props.entry.metadata.other) {
        if (props.entry.metadata.other[property] instanceof Array) {
            for (const item of props.entry.metadata.other[property]) {
                if (item.subtotal !== "$0.00") {
                    summary.push(`${item.description}: ${item.subtotal}`);
                }
            }
        } else {
            if (props.entry.metadata.other[property].subtotal !== "$0.00") {
                summary.push(
                    `${titleCase(property)}: ${
                        props.entry.metadata.other[property].subtotal
                    }`
                );
            }
        }
    }

    return summary;
});

const removeRow = () => {
    emit("rowRemoved", props.entry);
};

const hoursDisplay = computed(() => {
    if (!props.entry.metadata.hourly) {
        return;
    }

    return parseFloat(props.entry.metadata.hourly.hours).toFixed(2);
});

const travelSet = computed(() => {
    return props.entry.travel !== null;
});

const rowIcons = {
    user: {
        icon: UserIcon,
        title: "Added by user",
    },
    "non-system": {
        icon: UsersIcon,
        title: "Added by user or admin",
    },
    admin: {
        icon: StarIcon,
        title: "Added by admin",
    },
};
</script>
