import _ from "lodash";
import moment from "moment";

export function friendlyDate(value) {
    return new moment(value).format("MM/DD/YY");
}

export function titleCase(value) {
    return _.startCase(value);
}

export function currency(value) {
    value = value ?? 0;

    return (
        (value < 0 ? "-" : "") +
        "$" +
        parseFloat(Math.abs(value)).toFixed(2).toString()
    );
}

export function dateString(value) {
    return new moment(value).format("YYYY-MM-DD");
}
