<template>
    <!-- Breadcrumbs -->
    <div>
        <!-- Mobile Back Button -->
        <nav class="sm:hidden" aria-label="Back">
            <button
                type="button"
                class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                @click.prevent="goBack"
            >
                <ChevronLeftIcon
                    class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                Back
            </button>
        </nav>

        <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
                <li v-for="(breadcrumb, index) in breadcrumbs">
                    <div v-if="index === 0" class="flex">
                        <Link
                            :href="breadcrumb.url"
                            class="text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                            v-text="breadcrumb.label"
                        >
                        </Link>
                    </div>
                    <div v-else class="flex items-center">
                        <ChevronRightIcon
                            class="h-5 w-5 flex-shrink-0 text-gray-400 dark:text-gray-500"
                            aria-hidden="true"
                        />
                        <Link
                            v-if="
                                breadcrumb.hasOwnProperty('url') &&
                                breadcrumb.url !== null
                            "
                            :href="breadcrumb.url"
                            class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                            v-text="breadcrumb.label"
                        >
                        </Link>
                        <span
                            v-else
                            class="ml-4 text-sm font-medium text-gray-500 dark:text-gray-400"
                            aria-current="page"
                            v-text="breadcrumb.label"
                        ></span>
                    </div>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";

defineProps({
    breadcrumbs: Array,
});

const goBack = () => window.history.back();
</script>
