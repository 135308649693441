<template>
    <div class="mt-8 text-center md:mt-16">
        <component
            v-if="!slots.icon"
            :is="icon"
            class="mx-auto size-12 text-gray-400 dark:text-gray-500"
            aria-hidden="true"
        />
        <slot name="icon" />
        <h3 class="mt-2 text-sm font-medium text-gray-900 dark:text-gray-200">
            <slot />
        </h3>
        <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
            <slot name="subtitle" />
        </p>
        <div class="mt-6">
            <slot name="button" />
        </div>
    </div>
</template>

<script setup>
import { useSlots } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
    icon: {
        type: [Object, Function],
        default: MagnifyingGlassIcon,
    },
});

const slots = useSlots();
</script>
