<template>
    <div
        class="flex gap-2 bg-white px-4 py-2 text-gray-900 dark:bg-gray-950 dark:text-gray-200"
    >
        <button @click="darkMode.setLight()">Light</button>
        <button @click="darkMode.setDark()">Dark</button>
        <button @click="darkMode.useSystem()">System</button>
    </div>
</template>

<script setup>
import { inject } from "vue";
const darkMode = inject("darkMode");
</script>
