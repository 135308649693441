<template>
    <div
        class="grid h-auto w-full grid-flow-row px-4 py-5 shadow transition duration-1000 sm:rounded-lg md:p-6"
        :class="[theme.background, { 'animate-pulse': loading }]"
    >
        <h3 class="flex w-full text-lg sm:h-10 transition-opacity duration-1000" :class="[theme.headingText, {'opacity-0': loading}]">
            {{ title }}
            <span class="ml-3 block md:hidden" :class="[theme.badgeColor], {'opacity-0': loading}">
                <component :is="theme.icon" aria-hidden="true" />
            </span>
        </h3>
        <div
            class="grid grid-flow-row sm:h-20 md:grid-flow-col md:grid-cols-3"
        >
            <div class="flex h-full w-full items-center transition-opacity duration-1000 text-sm md:col-span-2" :class="[theme.bodyText, {'opacity-0': loading}]">
                <div v-html="body"></div>
            </div>
            <div
                class="hidden h-full w-full items-center justify-center md:col-span-1 md:flex"
            >
                <div :class="theme.badgeColor">
                    <div v-show="loading">
                        <LoadingSpinner class="h-12 w-12" />
                    </div>
                    <div v-show="!loading" class="transition-opacity duration-1000 " :class="{'opacity-0': loading}">
                        <template v-if="badgeType === 'Icon'">
                            <component
                                :is="icon"
                                class="h-12 w-12"
                                :class="theme.badgeColor"
                                aria-hidden="true"
                            />
                        </template>
                        <template v-else-if="badgeType === 'Html'">
                            <div v-html="badge"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="mt-4 flex w-full items-end space-x-4 text-sm sm:mt-0 sm:h-10 duration-1000  transition-opacity"
            :class="{'opacity-0': loading}"
        >
            <Link
                v-for="action in actions"
                :href="action.route"
                class="st-link"
                >{{ action.label }}</Link
            >
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
import LoadingSpinner from "@/Shared/LoadingSpinner.vue";

const props = defineProps({
    data: Object
});

const theme = props.data.theme
const badgeType = props.data.badgeType
const title = props.data.title
const body = props.data.body
const badge = props.data.badge
const actions = props.data.actions
const loading = ref(false);
const icons = {
    "alert-success": CheckCircleIcon,
    "alert-warning-triangle": ExclamationTriangleIcon,
    "alert-danger": XCircleIcon,
    "alert-info": InformationCircleIcon,
}
const icon = icons[props.data.theme.iconName]
</script>
