<template>
    <section :aria-labelledby="headingId">
        <div class="shadow sm:rounded-md dark:shadow-white/50">
            <div
                class="bg-white px-4 py-6 sm:rounded-t-md sm:p-6 dark:bg-gray-800"
            >
                <div>
                    <h2
                        :id="headingId"
                        class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200"
                    >
                        {{ title }}
                    </h2>
                    <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
                        <slot name="subheading" />
                    </p>
                </div>

                <div class="mt-6 grid grid-cols-4 gap-6">
                    <slot />
                </div>
            </div>

            <slot name="buttons" />
        </div>
    </section>
</template>

<script setup>
import { computed } from "vue";
import { kebabCase } from "lodash";

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
});

const headingId = computed(() => {
    return kebabCase(props.title) + "-heading";
});
</script>
