<template>
    <th
        scope="col"
        class="px-3 py-3.5 text-sm font-semibold text-gray-900 first:pl-4 first:pr-3 last:relative last:pl-3 last:pr-4 first:sm:pl-6 last:sm:pr-6 first:lg:pl-8 last:lg:pr-8 dark:text-gray-200"
        :class="{
            'text-left': justify === 'left',
            'text-center': justify === 'center',
            'text-right': justify === 'right',
            'hidden sm:table-cell': stackAt === 'sm',
            'hidden md:table-cell': stackAt === 'md',
            'hidden lg:table-cell': stackAt === 'lg',
            'hidden xl:table-cell': stackAt === 'xl',
        }"
    >
        <template v-if="isSortable">
            <button type="button" @click="updateSort" class="group inline-flex">
                <slot />
                <span
                    class="ml-2 flex-none rounded"
                    :class="{
                        'invisible text-gray-400 group-hover:visible group-focus:visible dark:text-gray-300':
                            !currentSort,
                        'bg-gray-200 text-gray-900 group-hover:bg-gray-300 dark:bg-gray-400 dark:text-gray-600 dark:group-hover:text-gray-500':
                            currentSort,
                    }"
                >
                    <component
                        :is="icon"
                        class="size-5"
                        :class="{
                            'invisible flex-none rounded group-hover:visible group-focus:visible ':
                                !currentSort,
                        }"
                        aria-hidden="true"
                    />
                </span>
            </button>
        </template>
        <template v-else>
            <slot />
        </template>
    </th>
</template>

<script setup>
import { inject, computed } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
    stackAt: {
        validator(value) {
            return ["sm", "md", "lg", "xl", false].includes(value);
        },
        default: false,
    },
    sortField: {
        type: String,
        default: null,
    },
    justify: {
        validator(value) {
            return ["left", "center", "right"].includes(value);
        },
        default: "left",
    },
});

let isSortable = props.sortField !== null;

let sorting = isSortable ? inject("sorting") : {};
let currentSort = isSortable ? sorting.value.field === props.sortField : false;
let sortDesc = isSortable ? sorting.value.asc === false : false;

const icon = computed(() => {
    return sortDesc && currentSort ? ChevronUpIcon : ChevronDownIcon;
});

const updateSort = () => {
    if (currentSort) {
        sorting.value = { field: props.sortField, asc: !sorting.value.asc };
        return;
    }

    sorting.value = { field: props.sortField, asc: true };
};
</script>
