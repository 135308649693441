<template>
    <div>
        <Combobox
            as="div"
            v-model="model"
            @update:modelValue="query = ''"
            :disabled="disabled"
            v-bind="$attrs"
            class="grid gap-1"
            :class="{
                'grid-rows-[auto_40px_20px]': errorField,
                'grid-rows-[auto_40px]': !errorField,
            }"
        >
            <div class="flex items-start">
                <ComboboxLabel
                    class="block text-sm/6 font-medium text-gray-900 dark:text-gray-200"
                    ><slot
                /></ComboboxLabel>
                <RequiredIcon v-if="required" />
            </div>
            <div class="relative">
                <ComboboxInput
                    class="block h-10 w-full rounded-md py-1.5 pl-3 pr-12 text-base outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 focus:ring-0 enabled:bg-white disabled:bg-gray-50 sm:text-sm/6 dark:enabled:bg-white/5 dark:disabled:bg-gray-800"
                    :class="{
                        ' text-gray-900 outline-gray-300 placeholder:text-gray-400 focus:outline-blue-600 focus:ring-blue-600 dark:text-gray-200 dark:outline-gray-500 dark:placeholder:text-gray-400 dark:focus:outline-blue-500 dark:focus:ring-blue-500':
                            !error,
                        ' text-red-900 outline-red-300 placeholder:text-red-300 focus:outline-red-600 focus:ring-red-600 dark:text-red-300 dark:outline-red-700 dark:placeholder:text-red-500/50 dark:focus:outline-red-500 dark:focus:ring-red-500':
                            error,
                    }"
                    @change="query = $event.target.value"
                    @blur="query = ''"
                    :display-value="(option) => option?.[label]"
                    :placeholder="placeholder"
                />
                <FormErrorIcon :error="error" class="pr-8" />
                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none disabled:hidden"
                >
                    <ChevronUpDownIcon
                        class="size-5 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="options.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm dark:bg-gray-900 dark:ring-gray-500"
                >
                    <slot name="options">
                        <DefaultComboboxOption
                            v-if="hasNoneOption"
                            index="none"
                            :option="noneOption"
                        />

                        <DefaultComboboxOption
                            v-for="(option, index) in filteredOptions"
                            :key="option[key]"
                            :option="option"
                            :index="index"
                        />
                    </slot>
                </ComboboxOptions>
            </div>
            <FormErrors v-if="error" :error="error" />
        </Combobox>
    </div>
</template>
<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { computed, ref, provide } from "vue";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOptions,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import RequiredIcon from "@/Shared/Forms/Components/RequiredIcon.vue";
import DefaultComboboxOption from "@/Shared/Forms/Components/DefaultComboboxOption.vue";

const model = defineModel();

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    field: {
        type: String,
        required: true,
    },
    config: {
        type: Object,
        default: {},
    },
    options: Array,
    placeholder: String,
    error: String,
    required: Boolean,
    noneOption: {
        type: Object,
        default: null,
    },
    errorField: {
        type: Boolean,
        default: true,
    },
});

const defaultConfig = {
    key: "id",
    label: "name",
};

const query = ref("");

const hasNoneOption = computed(() => {
    return props.noneOption !== null;
});

const key = computed(() => props.config?.key ?? defaultConfig.key);
const label = computed(() => props.config?.label ?? defaultConfig.label);

const filteredOptions = computed(() =>
    query.value === ""
        ? props.options
        : props.options.filter((option) => {
              return option[label.value]
                  .toLowerCase()
                  .includes(query.value.toLowerCase());
          })
);

provide("config", {
    key: key.value,
    label: label.value,
    field: props.field,
});
</script>
