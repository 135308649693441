<template>
    <svg
        viewBox="0 0 12.7 12.7"
        xmlns="http://www.w3.org/2000/svg"
        v-bind="$attrs"
    >
        <g transform="translate(-73.025 -73.025)">
            <path
                d="M78.846 73.978l-1.323.926-1.59.28-.749.75-.28 1.59-.926 1.322v1.059l.926 1.323.28 1.59.749.748 1.59.28 1.323.927h1.058l1.323-.926 1.59-.28.749-.749.28-1.59.927-1.323v-1.059l-.927-1.323-.28-1.59-.748-.748-1.59-.28-1.324-.927zm0 .47v1.048a3.916 3.916 0 0 1 .53-.036 3.916 3.916 0 0 1 .528.036v-1.049l1.173.821 1.409.248-.741.742a3.916 3.916 0 0 1 .748.748l.741-.741.249 1.41.82 1.171h-1.048a3.916 3.916 0 0 1 .036.53 3.916 3.916 0 0 1-.036.529h1.049l-.821 1.172-.249 1.41-.741-.742a3.916 3.916 0 0 1-.748.748l.74.741-1.408.249-1.173.82v-1.048a3.916 3.916 0 0 1-.529.036 3.916 3.916 0 0 1-.529-.036v1.049l-1.172-.821-1.41-.249.742-.74a3.916 3.916 0 0 1-.749-.75l-.74.742-.25-1.41-.82-1.171h1.048a3.916 3.916 0 0 1-.036-.53 3.916 3.916 0 0 1 .036-.529h-1.048l.82-1.172.25-1.41.74.742a3.916 3.916 0 0 1 .749-.748l-.741-.742 1.409-.248zM79.798 73.448a.423.423 0 0 1-.423.423.423.423 0 0 1-.424-.423.423.423 0 0 1 .424-.423.423.423 0 0 1 .423.423M79.798 85.302a.423.423 0 0 0-.423-.423.423.423 0 0 0-.424.423.423.423 0 0 0 .424.423.423.423 0 0 0 .423-.423M83.865 75.484a.423.423 45 0 1-.599 0 .423.423 45 0 1 0-.599.423.423 45 0 1 .599 0 .423.423 45 0 1 0 .599M75.484 83.865a.423.423 45 0 0 0-.599.423.423 45 0 0-.599 0 .423.423 45 0 0 0 .599.423.423 45 0 0 .599 0M85.302 79.798a.423.423 0 0 1-.423-.423.423.423 0 0 1 .423-.424.423.423 0 0 1 .423.424.423.423 0 0 1-.423.423M73.448 79.798a.423.423 0 0 0 .423-.423.423.423 0 0 0-.423-.424.423.423 0 0 0-.423.424.423.423 0 0 0 .423.423M74.885 75.484a.423.423 45 0 0 .599 0 .423.423 45 0 0 0-.599.423.423 45 0 0-.599 0 .423.423 45 0 0 0 .599M83.266 83.865a.423.423 45 0 1 0-.599.423.423 45 0 1 .599 0 .423.423 45 0 1 0 .599.423.423 45 0 1-.599 0"
                :class="
                    secondary ?? 'fill-brand-accent dark:fill-brand-accent-dark'
                "
            />
            <circle
                cx="79.375"
                cy="79.375"
                r="3.81"
                :class="white ?? 'fill-white dark:fill-gray-200'"
            />
            <path
                transform="matrix(.08615 0 0 .08654 72.537 72.446)"
                d="M79.375 44.979l-12.7 7.938h4.233v9.524H55.033L50.8 78.316h4.233V98.16H44.98v5.028h68.792V98.16h-10.054V78.316h4.234l-4.234-15.875H87.842v-9.525h4.234z"
                :class="primary ?? 'fill-brand dark:fill-brand-dark'"
            />
            <path
                transform="matrix(.02117 0 0 .02117 62.907 71.729)"
                d="M778.6 325.25l-15.467 4.24c-8.508 2.332-15.469 4.782-15.469 5.444 0 .66 2.622 1.833 5.828 2.603l5.83 1.4.776 17.551c.842 19.015 1.91 21.693 10.104 25.336h.002c9.44 4.198 18.794 1.183 24.156-7.789 2.46-4.116 3.034-8 3.039-20.537.004-11.297.53-15.466 1.949-15.482 1.069-.012 3.516-.452 5.44-.978 3.313-.909 5.13-.354 3.497 7.95-1 5.085.728 9.252 1.442 8.905 1.416-.687.402-17.518-1.25-19.248-.533-.557-7.473-2.9-15.422-5.205zm-22.348 61.375c-.923.06-6.83.36-10.502 4.287-1.71 1.122-2.734 5.277-4.244 15.1-1.024 6.656-2.23 13.708-2.684 15.67l-.82 3.569h80l-.826-4.362c-.454-2.398-1.697-9.954-2.762-16.79-1.194-7.667-2.765-12.756-4.1-13.278-.036-.014-.067-.023-.103-.037-.57-1.09-2.547-3.565-8.959-4.002-8.25-.562-23.035 9.85-23.035 9.85s-11.027-6.162-14.527-7.756-6.469-2.313-7.437-2.25z"
                :class="white ?? 'fill-white dark:fill-gray-200'"
            />
        </g>
    </svg>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
    primary: String,
    secondary: String,
    white: String,
});
</script>
