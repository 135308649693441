<template>
    <div
        class="flex items-center justify-between py-2"
        :class="{ 'px-4': !mobileMenu, 'px-3': mobileMenu }"
    >
        <div
            :class="{
                'text-sm': !mobileMenu,
                'text-base font-medium': mobileMenu,
            }"
        >
            Theme
        </div>
        <div
            class="inline-flex"
            :class="{ 'space-x-4': !mobileMenu, 'space-x-8': mobileMenu }"
        >
            <ThemeButton
                theme="light"
                @onThemeChange="setTheme"
                :mobile-menu="mobileMenu"
            />
            <ThemeButton
                theme="dark"
                @onThemeChange="setTheme"
                :mobile-menu="mobileMenu"
            />
            <ThemeButton
                theme="system"
                @onThemeChange="setTheme"
                :mobile-menu="mobileMenu"
            />
        </div>
    </div>
</template>

<script setup>
import { inject, ref, provide } from "vue";
import ThemeButton from "@/Shared/ThemeButton.vue";

const props = defineProps({
    mobileMenu: {
        type: Boolean,
        default: false,
    },
});

const darkMode = inject("darkMode");
const current = ref(darkMode.getCurrentTheme());
provide("current", current);

const setTheme = (theme) => {
    darkMode.setTheme(theme);
    current.value = theme;
};
</script>
