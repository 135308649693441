<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative" @close="open = false">
            <template v-if="overlay">
                <TransitionChild
                    as="template"
                    enter="ease-in-out duration-500"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div
                        class="fixed inset-0 z-20 bg-gray-500/75 transition-opacity dark:bg-gray-700/75"
                    />
                </TransitionChild>
            </template>
            <template v-else>
                <div class="fixed inset-0" />
            </template>

            <div class="fixed inset-0 z-30 overflow-hidden">
                <div class="slideover absolute inset-0 overflow-hidden">
                    <div
                        class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
                        :class="{
                            'pl-10': size === 'base',
                            'pl-10 sm:pl-16': size === 'wide',
                        }"
                    >
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel
                                class="pointer-events-auto w-screen"
                                :class="{
                                    'max-w-md': size === 'base',
                                    'max-w-2xl': size === 'wide',
                                }"
                            >
                                <div
                                    class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl dark:bg-gray-900"
                                >
                                    <div class="px-4 sm:px-6">
                                        <div
                                            class="flex items-start justify-between"
                                        >
                                            <DialogTitle
                                                class="text-lg font-medium text-gray-900 dark:text-gray-200"
                                                >{{ title }}</DialogTitle
                                            >
                                            <div
                                                class="ml-3 flex h-7 items-center"
                                            >
                                                <button
                                                    type="button"
                                                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-white dark:bg-gray-900 dark:text-gray-500 dark:hover:text-gray-400 dark:focus:ring-gray-400 dark:focus:ring-offset-gray-900"
                                                    @click="open = false"
                                                >
                                                    <span class="sr-only"
                                                        >Close panel</span
                                                    >
                                                    <XMarkIcon
                                                        class="size-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="relative mt-6 flex-1 px-4 sm:px-6"
                                    >
                                        <slot />
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const open = ref(false);

const emit = defineEmits(["slideover-closed"]);

defineProps({
    title: {
        type: String,
        required: true,
    },
    size: {
        validator(value) {
            return ["base", "wide"].includes(value);
        },
        default: "base",
    },
    overlay: {
        type: Boolean,
        default: true,
    },
});

defineExpose({
    open,
    close,
});
</script>
