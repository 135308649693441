<template>
    <svg
        class="group"
        viewBox="0 0 49.216645 16.932517"
        xmlns="http://www.w3.org/2000/svg"
        v-bind="$attrs"
    >
        <g
            :class="
                secondary ?? 'fill-brand-accent dark:fill-brand-accent-dark'
            "
        >
            <path
                d="m 7.76444,1.2692585 -1.7638,1.2351 -2.1205,0.37389 -0.99781,0.99781 -0.37389,2.1205 -1.235,1.7638 v 1.4111 l 1.235,1.7637995 0.37389,2.1205 0.99781,0.99781 2.1205,0.37388 1.7638,1.2351 h 1.4111 l 1.7638,-1.2351 2.1205,-0.37388 0.99781,-0.99781 0.37389,-2.1205 1.235,-1.7637995 v -1.4111 l -1.235,-1.7638 -0.37389,-2.1205 -0.99781,-0.99781 -2.1205,-0.37389 -1.7638,-1.2351 z m 0,0.62552 v 1.3979 a 5.2211,5.2211 0 0 1 0.70556,-0.0479 5.2211,5.2211 0 0 1 0.70555,0.0479 v -1.3979 l 1.563,1.0944 1.879,0.33134 -0.98844,0.98844 a 5.2211,5.2211 0 0 1 0.99781,0.99781 l 0.98844,-0.98844 0.33134,1.879 1.0944,1.563 h -1.3979 a 5.2211,5.2211 0 0 1 0.0479,0.70555 5.2211,5.2211 0 0 1 -0.0479,0.70556 h 1.3979 l -1.0944,1.5629995 -0.33129,1.8791 -0.98849,-0.98849 a 5.2211,5.2211 0 0 1 -0.99781,0.99781 l 0.98844,0.98843 -1.879,0.33134 -1.563,1.0944 v -1.3979 a 5.2211,5.2211 0 0 1 -0.70555,0.0479 5.2211,5.2211 0 0 1 -0.70556,-0.0479 v 1.3979 l -1.563,-1.0944 -1.879,-0.33134 0.98844,-0.98843 a 5.2211,5.2211 0 0 1 -0.99781,-0.99781 l -0.98844,0.98844 -0.33133,-1.879 -1.0944,-1.5629995 h 1.3979 a 5.2211,5.2211 0 0 1 -0.0479,-0.70556 5.2211,5.2211 0 0 1 0.0479,-0.70555 H 1.8989 l 1.0944,-1.563 0.33133,-1.879 0.98844,0.98844 a 5.2211,5.2211 0 0 1 0.99781,-0.99781 l -0.98844,-0.98844 1.879,-0.33134 z"
                id="path2"
            />
            <path
                d="M 9.03444,0.56425849 A 0.56444,0.56444 0 0 1 8.47,1.1286985 0.56444,0.56444 0 0 1 7.90555,0.56425849 0.56444,0.56444 0 0 1 8.47,-1.9150746e-4 0.56444,0.56444 0 0 1 9.03444,0.56425849"
                id="path3"
            />
            <path
                d="M 9.03444,16.368258 A 0.56444,0.56444 0 0 0 8.47,15.803818 0.56444,0.56444 0 0 0 7.90555,16.368258 0.56444,0.56444 0 0 0 8.47,16.932708 0.56444,0.56444 0 0 0 9.03444,16.368258"
                id="path4"
            />
            <path
                d="m 14.45444,3.2772585 a 0.56444,0.56444 0 0 1 -0.79824,0 0.56444,0.56444 0 0 1 0,-0.79825 0.56444,0.56444 0 0 1 0.79824,0 0.56444,0.56444 0 0 1 0,0.79825"
                id="path5"
            />
            <path
                d="m 3.28444,14.453258 a 0.56444,0.56444 0 0 0 0,-0.79825 0.56444,0.56444 0 0 0 -0.79824,0 0.56444,0.56444 0 0 0 0,0.79825 0.56444,0.56444 0 0 0 0.79824,0"
                id="path6"
            />
            <path
                d="m 16.37444,9.0302585 a 0.56444,0.56444 0 0 1 -0.56445,-0.56445 0.56444,0.56444 0 0 1 0.56445,-0.56444 0.56444,0.56444 0 0 1 0.56444,0.56444 0.56444,0.56444 0 0 1 -0.56444,0.56445"
                id="path7"
            />
            <path
                d="M 0.56444,9.0302585 A 0.56444,0.56444 0 0 0 1.12889,8.4658085 0.56444,0.56444 0 0 0 0.56444,7.9013685 0.56444,0.56444 0 0 0 0,8.4658085 a 0.56444,0.56444 0 0 0 0.56444,0.56445"
                id="path8"
            />
            <path
                d="m 2.48444,3.2772585 a 0.56444,0.56444 0 0 0 0.79824,0 0.56444,0.56444 0 0 0 0,-0.79825 0.56444,0.56444 0 0 0 -0.79824,0 0.56444,0.56444 0 0 0 0,0.79825"
                id="path9"
            />
            <path
                d="m 13.65444,14.453258 a 0.56444,0.56444 0 0 1 0,-0.79825 0.56444,0.56444 0 0 1 0.79824,0 0.56444,0.56444 0 0 1 0,0.79825 0.56444,0.56444 0 0 1 -0.79824,0"
                id="path10"
            />
        </g>
        <circle
            cx="8.4644394"
            cy="8.466258"
            r="5.0799999"
            :class="white ?? 'fill-white dark:fill-gray-200'"
            id="circle11"
        />
        <path
            d="M 8.4722462,4.4173853 7.0133972,5.3332934 H 7.4996764 V 6.4323832 H 5.6761152 L 5.189836,8.2641994 h 0.4862792 v 2.2897986 h -1.154903 v 0.580077 H 12.423349 V 10.553998 H 11.268446 V 8.2641994 h 0.486279 L 11.268446,6.4323832 H 9.444885 V 5.3332934 h 0.4862792 z"
            id="path11"
            :class="primary ?? 'fill-brand dark:fill-brand-dark'"
        />
        <path
            d="M 8.4880892,7.450464 8.0515795,7.5701309 c -0.2400986,0.065819 -0.4365661,0.1349576 -0.4365661,0.1536237 0,0.018662 0.074001,0.051731 0.1644806,0.073476 l 0.1645371,0.039522 0.021883,0.4953243 c 0.023761,0.5366414 0.053918,0.6122199 0.2851551,0.7150326 h 5.64e-5 c 0.2664467,0.1184619 0.5304043,0.033361 0.6817306,-0.2198239 0.069423,-0.1161674 0.085637,-0.2258099 0.085769,-0.5795953 1.129e-4,-0.3188239 0.01496,-0.4364814 0.05501,-0.436933 0.030161,-3.386e-4 0.099228,-0.012773 0.1535135,-0.027615 0.093522,-0.025639 0.1447958,-0.00999 0.098721,0.2243424 -0.028222,0.143537 0.020544,0.2611268 0.040679,0.2513507 0.039968,-0.019403 0.011354,-0.494393 -0.035278,-0.543217 C 9.3162412,7.6998799 9.1203887,7.6337571 8.8960323,7.5687195 Z M 7.8573839,9.1825893 c -0.026046,0.00168 -0.192759,0.010179 -0.2963874,0.1209905 -0.048248,0.031668 -0.077156,0.1489359 -0.119777,0.4261522 -0.028905,0.1878541 -0.062938,0.386867 -0.075737,0.442238 l -0.023151,0.100708 h 2.25776 L 9.576776,10.149593 C 9.5639677,10.081905 9.5288861,9.8686745 9.4988353,9.6757179 9.4651326,9.4593511 9.4208043,9.3157463 9.3831364,9.3010144 9.3821006,9.300608 9.3812314,9.300354 9.3802148,9.2999674 9.3641074,9.2692054 9.3083221,9.1993616 9.1273739,9.1870229 8.8945424,9.1711478 8.4772802,9.4649983 8.4772802,9.4649983 c 0,0 -0.311204,-0.1739067 -0.409981,-0.218887 -0.098777,-0.04498 -0.1825597,-0.065263 -0.2099011,-0.063499 z"
            :class="white ?? 'fill-white dark:fill-gray-200'"
            id="path12"
        />
        <path
            d="M 21.474978,3.9928766 Q 21.425588,3.9434866 21.291527,3.8588138 21.164525,3.7741448 20.974013,3.696526 20.790562,3.611857 20.571835,3.5554123 q -0.218727,-0.056447 -0.444516,-0.056447 -0.402177,0 -0.599741,0.1481753 -0.197563,0.1481753 -0.197563,0.4162904 0,0.1552262 0.07056,0.2610641 0.07761,0.098781 0.218727,0.1763895 0.141114,0.077613 0.35279,0.1411138 0.218727,0.063502 0.500965,0.1340628 0.366902,0.098781 0.663242,0.211676 0.303401,0.1128888 0.508016,0.2822275 0.211676,0.1693386 0.324565,0.4092394 0.112889,0.2328393 0.112889,0.5785781 0,0.4021779 -0.155227,0.6914669 -0.148175,0.2822275 -0.409239,0.4586277 -0.254003,0.1763895 -0.59268,0.2610642 -0.331626,0.077613 -0.698518,0.077613 -0.564465,0 -1.114808,-0.1693387 Q 18.56094,7.4078772 18.123486,7.0974248 l 0.430403,-0.8396422 q 0.0635,0.063502 0.225788,0.1693386 0.169339,0.098781 0.395127,0.2046144 0.225789,0.098781 0.500965,0.1693387 0.275177,0.070558 0.564466,0.070558 0.804356,0 0.804356,-0.5150668 0,-0.1622877 -0.09173,-0.2751766 Q 20.86114,5.9685 20.691801,5.8838253 20.522463,5.799156 20.282562,5.7285991 20.042661,5.6580423 19.746321,5.573373 19.38648,5.4745923 19.118355,5.361697 18.85729,5.2417466 18.680901,5.0865204 18.504511,4.9242327 18.412786,4.7196183 q -0.08467,-0.211676 -0.08467,-0.500965 0,-0.3810146 0.141113,-0.6773545 0.141114,-0.2963399 0.388066,-0.4939034 0.254002,-0.1975636 0.585629,-0.29634 0.331616,-0.1058369 0.712631,-0.1058369 0.529179,0 0.973694,0.1693386 0.444516,0.1622878 0.776131,0.3880655 z"
            id="path13"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 23.981723,7.7605677 q -0.571517,0 -0.867857,-0.3669021 -0.29634,-0.3669022 -0.29634,-1.0865407 V 3.9928126 h 0.94547 v 2.1097193 q 0,0.853744 0.613854,0.853744 0.275176,0 0.529179,-0.1622877 0.261064,-0.1693387 0.423352,-0.5080159 v -2.293085 h 0.945469 v 2.6106416 q 0,0.1481753 0.04939,0.2116759 0.05645,0.063502 0.176389,0.070558 v 0.8043558 q -0.141114,0.028223 -0.239901,0.035279 -0.09172,0.00706 -0.169338,0.00706 -0.254003,0 -0.416291,-0.1128889 -0.155225,-0.1199542 -0.18345,-0.3245686 L 25.470482,6.9986602 Q 25.22353,7.3796747 24.835464,7.5701767 24.447399,7.7606786 23.98172,7.7606786 Z"
            id="path14"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 27.10182,5.8414677 q 0,-0.3880654 0.134063,-0.7338042 0.134063,-0.3457388 0.388065,-0.6067923 0.254003,-0.2610642 0.613854,-0.4162904 0.359841,-0.1552262 0.811418,-0.1552262 0.606792,0 1.030144,0.2610642 0.430403,0.2610642 0.642079,0.6844054 L 29.797136,5.1570517 Q 29.677186,4.9524373 29.479622,4.8465993 29.282059,4.7337104 29.042169,4.7337104 q -0.204615,0 -0.381015,0.084669 -0.176389,0.077613 -0.310452,0.2257883 -0.127002,0.1411138 -0.204615,0.3457282 -0.07056,0.2046144 -0.07056,0.4515661 0,0.2469517 0.07761,0.4515661 0.07761,0.2046144 0.204614,0.3527897 0.134063,0.1481753 0.310452,0.2328393 0.17639,0.077613 0.373953,0.077613 0.254003,0 0.465679,-0.1270013 0.218727,-0.1270013 0.303401,-0.3104524 l 0.924307,0.2822275 q -0.190502,0.4233412 -0.627967,0.6914669 -0.437453,0.2681151 -1.051307,0.2681151 -0.451566,0 -0.811418,-0.1552262 Q 27.885018,7.450174 27.631005,7.1891098 27.377002,6.9209948 27.235878,6.575256 27.101815,6.2224663 27.101815,5.8414517 Z"
            id="path15"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 31.170214,5.8414677 q 0,-0.3880654 0.134063,-0.7338042 0.134063,-0.3457388 0.388065,-0.6067923 0.254003,-0.2610642 0.613854,-0.4162904 0.359841,-0.1552262 0.811417,-0.1552262 0.606793,0 1.030145,0.2610642 0.430402,0.2610642 0.642078,0.6844054 L 33.86553,5.1570517 Q 33.74558,4.9524373 33.548016,4.8465993 33.350453,4.7337104 33.110563,4.7337104 q -0.204615,0 -0.381015,0.084669 -0.17639,0.077613 -0.310452,0.2257883 -0.127002,0.1411138 -0.204615,0.3457282 -0.07056,0.2046144 -0.07056,0.4515661 0,0.2469517 0.07761,0.4515661 0.07762,0.2046144 0.204615,0.3527897 0.134063,0.1481753 0.310452,0.2328393 0.17639,0.077613 0.373953,0.077613 0.254003,0 0.465679,-0.1270013 0.218727,-0.1270013 0.303401,-0.3104524 l 0.924306,0.2822275 q -0.190497,0.4233417 -0.627961,0.6914674 -0.437454,0.2681151 -1.051307,0.2681151 -0.451567,0 -0.811418,-0.1552262 Q 31.953411,7.450174 31.699397,7.1891098 31.445395,6.9209948 31.30427,6.575256 31.170207,6.2224663 31.170207,5.8414517 Z"
            id="path16"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 37.186402,7.7605677 q -0.444515,0 -0.804356,-0.1481753 Q 36.022206,7.4571662 35.768192,7.1961021 35.51419,6.9350379 35.373065,6.5893098 35.239002,6.2435816 35.239002,5.8625671 q 0,-0.3951271 0.134063,-0.7408552 0.134063,-0.3527897 0.388066,-0.6138538 0.254002,-0.2681151 0.613854,-0.4233413 0.366902,-0.1552262 0.818468,-0.1552262 0.451566,0 0.804356,0.1552262 0.35984,0.1552262 0.606792,0.4162904 0.254002,0.2610641 0.381014,0.6067923 0.134063,0.3457281 0.134063,0.7196918 0,0.091725 -0.0071,0.1763895 0,0.084669 -0.01411,0.1411137 h -2.857583 q 0.02117,0.2187269 0.105837,0.3880655 0.08467,0.1693386 0.218727,0.289289 0.134058,0.1199505 0.303402,0.1834511 0.169344,0.063501 0.352789,0.063502 0.282228,0 0.52918,-0.1340628 0.254002,-0.1411138 0.345738,-0.3669022 l 0.811418,0.2257884 q -0.204615,0.4233413 -0.656191,0.6985179 -0.444516,0.268115 -1.06542,0.268115 z M 38.145984,5.5309514 Q 38.110705,5.114661 37.835532,4.8677093 37.567416,4.6137067 37.17934,4.6137067 q -0.190502,0 -0.35984,0.070558 -0.162288,0.063502 -0.289289,0.1834511 -0.127002,0.1199493 -0.211676,0.289289 -0.07761,0.1693387 -0.09173,0.3739531 z"
            id="path17"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 41.225995,7.7605677 q -0.465679,0 -0.910194,-0.1481753 -0.444515,-0.1481753 -0.762029,-0.4233412 l 0.35279,-0.5926799 q 0.338677,0.2399009 0.656191,0.3669022 0.324565,0.1199504 0.642078,0.1199504 0.282228,0 0.444516,-0.1058369 0.162287,-0.1058369 0.162287,-0.3034015 0,-0.1975635 -0.190502,-0.2892891 -0.190501,-0.091725 -0.620904,-0.2116759 -0.359841,-0.098781 -0.613854,-0.190502 -0.254013,-0.091721 -0.40924,-0.2046144 -0.155226,-0.1199504 -0.225788,-0.268115 -0.07056,-0.1552262 -0.07056,-0.3669021 0,-0.2822275 0.105836,-0.5080159 0.112889,-0.2257884 0.310452,-0.3810146 0.197564,-0.1622877 0.458628,-0.2469517 0.268115,-0.084669 0.578578,-0.084669 0.41629,0 0.776131,0.1199504 0.366902,0.1199504 0.670293,0.3880655 l -0.381013,0.5715162 q -0.282227,-0.211676 -0.550353,-0.3104524 -0.261064,-0.098781 -0.522128,-0.098781 -0.239901,0 -0.402178,0.098781 -0.162288,0.098781 -0.162288,0.3175139 0,0.098781 0.03528,0.1622878 0.04233,0.063502 0.127001,0.1128888 0.08467,0.04939 0.218727,0.098781 0.141114,0.042335 0.345728,0.091726 0.381015,0.098781 0.64913,0.1975635 0.275177,0.098781 0.444515,0.2257884 0.17639,0.1270013 0.254003,0.2963399 0.08467,0.1622877 0.08467,0.395127 0,0.5432916 -0.402178,0.8608056 -0.40218,0.3104513 -1.093689,0.3104513 z"
            id="path18"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 44.853841,7.7605677 q -0.465678,0 -0.910193,-0.1481753 -0.444516,-0.1481753 -0.762019,-0.4233412 l 0.35279,-0.5926799 q 0.338677,0.2399009 0.656191,0.3669022 0.324565,0.1199504 0.642079,0.1199504 0.282227,0 0.444515,-0.1058369 0.162288,-0.1058369 0.162288,-0.3034015 0,-0.1975635 -0.190502,-0.2892891 -0.190502,-0.091725 -0.620905,-0.2116759 -0.35984,-0.098781 -0.613854,-0.190502 -0.254013,-0.091721 -0.409239,-0.2046144 -0.155226,-0.1199504 -0.225789,-0.268115 -0.07056,-0.1552262 -0.07056,-0.3669021 0,-0.2822275 0.105837,-0.5080159 0.112889,-0.2257884 0.310453,-0.3810146 0.197563,-0.1622877 0.458627,-0.2469517 0.268115,-0.084669 0.578579,-0.084669 0.41629,0 0.77613,0.1199504 0.366903,0.1199504 0.670293,0.3880655 L 45.82755,5.0017679 q -0.282228,-0.211676 -0.550353,-0.3104524 -0.261065,-0.098781 -0.522129,-0.098781 -0.239901,0 -0.402178,0.098781 -0.162287,0.098781 -0.162287,0.3175139 0,0.098781 0.03528,0.1622878 0.04233,0.063502 0.127001,0.1128888 0.08467,0.04939 0.218727,0.098781 0.141113,0.042335 0.345728,0.091726 0.381014,0.098781 0.64913,0.1975635 0.275176,0.098781 0.444515,0.2257884 0.176389,0.1270013 0.254002,0.2963399 0.08467,0.1622877 0.08467,0.395127 0,0.5432916 -0.402178,0.8608056 Q 45.5453,7.7605897 44.85379,7.7605897 Z"
            id="path19"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 22.237668,10.237231 h -1.60165 v 4.155863 H 19.669375 V 10.237231 H 18.067724 V 9.3834877 h 4.169944 z"
            id="path20"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 23.802517,14.46371 q -0.451566,0 -0.811417,-0.155226 -0.359841,-0.155226 -0.613854,-0.416291 -0.246952,-0.268115 -0.381015,-0.613853 -0.134063,-0.345739 -0.134063,-0.726743 0,-0.388066 0.134063,-0.733804 0.134063,-0.345739 0.381015,-0.606793 0.254002,-0.268115 0.613854,-0.423352 0.35984,-0.155226 0.811417,-0.155226 0.451577,0 0.804356,0.155226 0.35984,0.155227 0.606792,0.423352 0.254003,0.261065 0.388066,0.606793 0.134062,0.345728 0.134062,0.733804 0,0.381014 -0.134062,0.726743 -0.134063,0.345728 -0.381015,0.613853 -0.246952,0.261065 -0.606792,0.416291 -0.359841,0.155226 -0.811418,0.155226 z m -0.966644,-1.912166 q 0,0.246951 0.07056,0.451566 0.07761,0.197563 0.204615,0.345738 0.134063,0.148176 0.310452,0.23284 0.17639,0.07761 0.381015,0.07761 0.204614,0 0.381014,-0.07761 0.17639,-0.08467 0.303402,-0.23284 0.134063,-0.148175 0.204614,-0.352789 0.07761,-0.204615 0.07761,-0.451566 0,-0.239901 -0.07761,-0.444516 -0.07056,-0.204614 -0.204614,-0.352789 -0.127002,-0.148176 -0.303402,-0.225789 -0.176389,-0.08467 -0.381014,-0.08467 -0.204615,0 -0.381015,0.08467 -0.176389,0.08467 -0.310452,0.23284 -0.127002,0.148175 -0.204615,0.352789 -0.07056,0.204615 -0.07056,0.444516 z"
            id="path21"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 27.940246,14.414642 q -0.373953,0 -0.677354,-0.148175 -0.303402,-0.148176 -0.529179,-0.402178 -0.218727,-0.261065 -0.338678,-0.599742 -0.11995,-0.338677 -0.11995,-0.719692 0,-0.402178 0.127001,-0.747906 0.127002,-0.345728 0.35279,-0.606792 0.225788,-0.261064 0.543292,-0.409239 0.317513,-0.148176 0.698517,-0.148176 0.430403,0 0.754968,0.197564 0.324565,0.190502 0.536241,0.515067 v -0.64913 h 0.82553 V 14.2171 q 0,0.409228 -0.155227,0.733804 -0.155226,0.324575 -0.430402,0.550353 -0.268115,0.225788 -0.64913,0.345739 -0.373953,0.11995 -0.818468,0.11995 -0.606793,0 -1.023083,-0.204614 -0.409239,-0.197564 -0.705579,-0.564466 l 0.515066,-0.500965 q 0.211676,0.261064 0.52918,0.40924 0.324564,0.148175 0.684405,0.148175 0.218727,0 0.41629,-0.0635 0.204615,-0.05645 0.359841,-0.183451 0.155226,-0.127001 0.239901,-0.324565 0.09172,-0.197563 0.09172,-0.465678 v -0.465679 q -0.183451,0.317514 -0.515066,0.493904 -0.331616,0.169338 -0.712631,0.169338 z m 0.317514,-0.754968 q 0.155226,0 0.29634,-0.04939 0.141114,-0.04939 0.261064,-0.134063 0.119951,-0.08467 0.211676,-0.197563 0.09173,-0.112889 0.141114,-0.239901 v -0.881969 q -0.127001,-0.324565 -0.402178,-0.522129 -0.268115,-0.197563 -0.564466,-0.197563 -0.218726,0 -0.395127,0.09878 -0.176389,0.09172 -0.303401,0.254002 -0.127001,0.155226 -0.197564,0.359841 -0.0635,0.204614 -0.0635,0.423352 0,0.225788 0.07761,0.423352 0.07761,0.197563 0.211676,0.345728 0.141114,0.148175 0.324565,0.232839 0.183451,0.08466 0.402177,0.08467 z"
            id="path22"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 32.813999,14.46371 q -0.444516,0 -0.804356,-0.148175 -0.359841,-0.155226 -0.613854,-0.416291 -0.254003,-0.261064 -0.395127,-0.606792 -0.134063,-0.345739 -0.134063,-0.726743 0,-0.395127 0.134063,-0.740855 0.134063,-0.352789 0.388065,-0.613854 0.254003,-0.268115 0.613854,-0.423352 0.366902,-0.155226 0.818468,-0.155226 0.451567,0 0.804356,0.155226 0.359841,0.155227 0.606793,0.416291 0.254002,0.261064 0.381014,0.606792 0.134063,0.345739 0.134063,0.719692 0,0.09173 -0.0071,0.176389 0,0.08467 -0.01411,0.141114 H 31.86853 q 0.02117,0.218727 0.105837,0.388066 0.08467,0.169338 0.218727,0.289289 0.134057,0.11995 0.303401,0.183451 0.169339,0.0635 0.35279,0.0635 0.282228,0 0.529179,-0.134062 0.254003,-0.141114 0.345728,-0.366903 l 0.811418,0.225789 q -0.204615,0.423341 -0.656191,0.698518 -0.444516,0.268115 -1.06542,0.268115 z m 0.959582,-2.229616 q -0.03528,-0.416291 -0.310453,-0.663242 -0.268115,-0.254003 -0.656191,-0.254003 -0.190502,0 -0.359841,0.07056 -0.162287,0.0635 -0.289289,0.183451 -0.127001,0.11995 -0.211676,0.289289 -0.07761,0.169339 -0.09172,0.373953 z"
            id="path23"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 37.645083,14.202369 q -0.190502,0.08467 -0.465679,0.169338 -0.275176,0.08467 -0.578578,0.08467 -0.197563,0 -0.373953,-0.04939 -0.169338,-0.04939 -0.303401,-0.155226 -0.127002,-0.112889 -0.204615,-0.282228 -0.07761,-0.176389 -0.07761,-0.423352 v -2.123799 h -0.486852 v -0.726743 h 0.486852 V 9.4961334 h 0.94547 v 1.1995036 h 0.776131 v 0.726743 h -0.776131 v 1.806243 q 0,0.197563 0.09878,0.282227 0.105837,0.07761 0.254003,0.07761 0.148166,0 0.289289,-0.04939 0.141114,-0.04939 0.225788,-0.08467 z"
            id="path24"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 41.708143,14.393308 h -0.945469 v -2.074412 q 0,-0.437453 -0.162288,-0.642078 -0.162288,-0.211676 -0.458628,-0.211676 -0.127001,0 -0.268115,0.05644 -0.141114,0.05645 -0.268115,0.162288 -0.127001,0.09878 -0.232839,0.239901 -0.105836,0.141113 -0.155226,0.310452 v 2.159108 h -0.94547 V 9.2426684 h 0.94547 v 2.1378796 q 0.204614,-0.359841 0.550353,-0.550353 0.35279,-0.197564 0.776131,-0.197564 0.35984,0 0.585629,0.127002 0.225788,0.11995 0.352789,0.324564 0.127002,0.204615 0.17639,0.465679 0.04939,0.261064 0.04939,0.536241 z"
            id="path25"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 44.371693,14.46371 q -0.444515,0 -0.804356,-0.148175 -0.35984,-0.155226 -0.613854,-0.416291 -0.254002,-0.261064 -0.395127,-0.606792 -0.134062,-0.345739 -0.134062,-0.726743 0,-0.395127 0.134062,-0.740855 0.134063,-0.352789 0.388066,-0.613854 0.254002,-0.268115 0.613854,-0.423352 0.366902,-0.155226 0.818468,-0.155226 0.451566,0 0.804356,0.155226 0.35984,0.155227 0.606792,0.416291 0.254003,0.261064 0.381015,0.606792 0.134063,0.345739 0.134063,0.719692 0,0.09173 -0.0071,0.176389 0,0.08467 -0.01411,0.141114 h -2.857582 q 0.02117,0.218727 0.105837,0.388066 0.08467,0.169338 0.218726,0.289289 0.134058,0.11995 0.303402,0.183451 0.169344,0.0635 0.35279,0.0635 0.282227,0 0.529179,-0.134062 0.254002,-0.141114 0.345739,-0.366903 l 0.811417,0.225789 q -0.204614,0.423341 -0.656191,0.698518 -0.444515,0.268115 -1.06542,0.268115 z m 0.959582,-2.229616 q -0.03528,-0.416291 -0.310452,-0.663242 -0.268115,-0.254003 -0.656191,-0.254003 -0.190502,0 -0.359841,0.07056 -0.162288,0.0635 -0.289289,0.183451 -0.127001,0.11995 -0.211676,0.289289 -0.07761,0.169339 -0.09173,0.373953 z"
            id="path26"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
        <path
            d="m 49.216645,11.514285 q -0.430403,0.007 -0.769081,0.169338 -0.338677,0.155226 -0.486852,0.47274 v 2.236657 h -0.94547 v -3.697182 h 0.867857 v 0.790243 q 0.09878,-0.190502 0.232839,-0.338677 0.134063,-0.155226 0.289289,-0.268115 0.155226,-0.112889 0.310453,-0.169339 0.162287,-0.0635 0.310452,-0.0635 0.07761,0 0.112889,0 0.04233,0 0.07761,0.0071 z"
            id="path27"
            :class="text ?? 'fill-white dark:fill-gray-200'"
        />
    </svg>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
    primary: String,
    secondary: String,
    white: String,
    text: String,
});
</script>
