<template>
    <div>
        <div class="flex h-6 items-center space-x-4">
            <button
                @click.prevent="undoRemove"
                class="st-link"
                v-show="removed.length > 0"
            >
                Undo
            </button>
            <button
                @click.prevent="restore"
                class="st-link"
                v-show="changesMade"
            >
                Restore
            </button>
        </div>
        <ul
            class="-mr-4 -mt-2 flex list-none flex-wrap items-center space-x-2 py-2"
        >
            <template v-for="tag in sortedTags" :key="tag[itemKey]">
                <Tag
                    size="lg"
                    :color="color"
                    :model="tag"
                    :label-field="labelField"
                    @remove="removeTag"
                />
            </template>
            <FormInput
                class="ml-4"
                maxLength="45"
                list="tag-suggestions"
                ref="tagInput"
                field="tag-input"
                :placeholder="placeholder"
                @keydown.enter.prevent="addTag"
                :error-field="false"
            />
            <datalist id="tag-suggestions">
                <option
                    v-for="suggestion in filteredSuggestions"
                    :value="suggestion"
                />
            </datalist>
        </ul>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import Tag from "@/Shared/Tags/Tag.vue";
import FormInput from "@/Shared/Forms/FormInput.vue";

const props = defineProps({
    suggestions: Array,
    placeholder: {
        type: String,
        default: "Add tag",
    },
    itemKey: {
        type: String,
        default: "id",
    },
    labelField: {
        type: String,
        default: "name",
    },
    color: {
        validator(value) {
            return [
                "green",
                "yellow",
                "red",
                "blue",
                "purple",
                "gray",
            ].includes(value);
        },
        default: "blue",
    },
});

const model = defineModel();

const tagInput = ref(null);
const tags = ref([]);
const removed = ref([]);
const original = ref([...model.value]);

tags.value = model.value;

const emit = defineEmits(["update:modelValue"]);

const sortedTags = computed(() => {
    return tags.value.sort((a, b) =>
        a[props.labelField].localeCompare(b[props.labelField])
    );
});

const filteredSuggestions = computed(() => {
    return props.suggestions.filter(
        (s) => !tags.value.some((t) => t[props.labelField] === s)
    );
});

const undoRemove = () => {
    tags.value.push(removed.value.pop());

    updateValue(tags.value);
};

const changesMade = computed(() => {
    return (
        tags.value
            .map((tag) => tag[props.itemKey])
            .sort()
            .join("|") !==
        original.value
            .map((tag) => tag[props.itemKey])
            .sort()
            .join("|")
    );
});

const restore = () => {
    tags.value = [...original.value];
    tagInput.value.value = "";
    removed.value = [];

    updateValue(tags.value);
};

const addTag = (e) => {
    if (!validate(e.target.value)) return;

    const tag = {
        [props.itemKey]: "new_" + Date.now(),
        [props.labelField]: e.target.value.trim(),
    };

    tags.value.push(tag);
    e.target.value = "";

    updateValue(tags.value);
};

const removeTag = (tag) => {
    removed.value.push(tag);
    tags.value = tags.value.filter(
        (t) => t[props.itemKey] !== tag[props.itemKey]
    );

    updateValue(tags.value);
};

const validate = (tag) => {
    if (isEmptyString(tag)) return false;
    if (isDuplicate(tag)) return false;

    return true;
};

const isEmptyString = (str) => str.trim() === "";

const isDuplicate = (tag) => {
    const str = tag.toLowerCase().trim();

    const duplicateTag = tags.value.find(
        (tag) => tag[props.labelField].toLowerCase().trim() === str
    );
    if (duplicateTag) {
        console.log(`Duplicate tag found: ${duplicateTag[props.labelField]}`);
        const duplicateTagElement = document.querySelector(
            `[data-tag="${duplicateTag[props.labelField]}"]`
        );
        if (duplicateTagElement) {
            duplicateTagElement.classList.add("bounce");
            setTimeout(() => {
                duplicateTagElement.classList.remove("bounce");
            }, 5000);
        }
        return true;
    }
    return false;
};

const updateValue = (value) => emit("update:modelValue", value);
</script>

<style scoped>
@keyframes bounce {
    0%,
    100% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    50% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
}
.bounce {
    animation: bounce 500ms 2 ease-in-out;
}
</style>
