<template>
    <Modal ref="modal" color="blue">
        <template #icon>
            <CalendarIcon
                class="size-6 text-blue-600 dark:text-blue-700"
                aria-hidden="true"
            />
        </template>
        {{
            showInputs
                ? "Create New Pay Period"
                : "Set Next Pay Period Current"
        }}?
        <template #description>
            <slot />
            <div v-show="showInputs">
                <div
                    class="mt-6 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-4"
                >
                    <DateInput
                        required
                        field="start_date"
                        :error="form.errors.start_date"
                        placeholder="Pay period start date"
                        v-model="form.start_date"
                        @input="clearError('start_date')"
                        :config="{
                            defaultDate: next.start_date
                                ? dayjs(next.start_date).toDate()
                                : new Date(),
                            setDefaultDate: !!next.start_date,
                        }"
                        >From</DateInput
                    >

                    <DateInput
                        required
                        field="end_date"
                        :error="form.errors.end_date"
                        placeholder="Pay period end date"
                        v-model="form.end_date"
                        :config="{
                            defaultDate: next.end_date
                                ? dayjs(next.end_date).toDate()
                                : new Date(),
                            setDefaultDate: !!next.end_date,
                        }"
                        @input="clearError('end_date')"
                        >To</DateInput
                    >

                    <DateInput
                        required
                        field="timesheet_due_date"
                        :error="form.errors.timesheet_due_date"
                        placeholder="Timesheet due date"
                        v-model="form.timesheet_due_date"
                        :config="{
                            defaultDate: next.timesheet_due_date
                                ? dayjs(next.timesheet_due_date).toDate()
                                : new Date(),
                            setDefaultDate: !!next.timesheet_due_date,
                        }"
                        @input="clearError('timesheet_due_date')"
                        >Timesheet Due Date</DateInput
                    >
                    <FormInput
                        required
                        field="timesheet_due_time"
                        :error="form.errors.timesheet_due_time"
                        placeholder="Ex. 11:59 PM or 23:59"
                        v-model="form.timesheet_due_time"
                        type="time"
                        @input="clearError('timesheet_due_time')"
                        >Time Due</FormInput
                    >
                    <InputBuffer>
                        <Toggle v-model="form.setCurrent">
                            <span class="text-sm font-medium"
                                >Set Current?</span
                            >
                        </Toggle>
                    </InputBuffer>
                </div>
            </div>
            <div v-show="!showInputs"></div>
        </template>
        <template #buttons>
            <SubmitButton
                @click.prevent="submit"
                :processing="form.processing"
                class="w-full sm:w-auto"
                >Create</SubmitButton
            >
            <Button
                color="white"
                @click="cancel"
                ref="cancelButtonRef"
                class="mt-3 w-full justify-center sm:mr-3 sm:mt-0 sm:w-auto sm:text-sm"
                >Cancel</Button
            >
        </template>
    </Modal>
</template>

<script setup>
import Modal from "./Modal.vue";
import { CalendarIcon } from "@heroicons/vue/20/solid";
import DateInput from "../Forms/DateInput.vue";
import FormInput from "../Forms/FormInput.vue";
import Button from "../Buttons/Button.vue";
import InputBuffer from "../Forms/Structure/InputBuffer.vue";
import Toggle from "../Forms/Toggle.vue";
import SubmitButton from "../Buttons/SubmitButton.vue";

import { ref } from "vue";
import { useForm } from "@inertiajs/vue3";
import dayjs from "dayjs";

const modal = ref(null);
const showInputs = ref(true);
const processing = ref(false);

const props = defineProps({
    next: {
        type: Object,
        default: {},
    },
    submitUrl: String,
});

let form = useForm({
    start_date: props.next.start_date
        ? dayjs(props.next.start_date).format("MM/DD/YYYY")
        : null,
    end_date: props.next.end_date
        ? dayjs(props.next.end_date).format("MM/DD/YYYY")
        : null,
    timesheet_due_date: props.next.timesheet_due_date
        ? dayjs(props.next.timesheet_due_date).format("MM/DD/YYYY")
        : null,
    timesheet_due_time: props.next.timesheet_due_date
        ? dayjs(props.next.timesheet_due_date).format("HH:mm")
        : null,
    setCurrent: true,
});

const cancel = () => {
    form.reset();
    form.clearErrors();
    modal.value.closeModal();
};

const submit = () => {
    processing.value = true;
    form.post(props.submitUrl, {
        onSuccess: (page) => {
            if (page.props.toast) {
                flash(page.props.toast);
            }
            modal.value.closeModal();
        },
        onFinish: (visit) => {
            processing.value = false;
        },
    });
};

const clearError = (field) => {
    if (form.errors[field]) {
        form.clearErrors(field);
    }
};

const openModal = () => {
    modal.value.openModal();
};

const closeModal = () => {
    modal.value.closeModal();
};

defineExpose({
    openModal,
    closeModal,
});
</script>
