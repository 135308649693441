<template>
    <Head>
        <title>ST Staff</title>
    </Head>

    <div
        class="flex min-h-screen flex-col justify-center bg-gray-100 py-12 sm:px-6 lg:px-8 dark:bg-gray-950"
    >
        <slot />
    </div>
</template>

<script setup></script>
