<template>
    <a class="st-link group" :href="link">
        <div class="flex items-center">
            <p v-text="email"></p>
            <div
                class="st-link ml-2 group-hover:text-blue-500 dark:group-hover:text-blue-300"
            >
                <AtSymbolIcon class="size-4 sm:hidden sm:group-hover:block" />
            </div>
        </div>
    </a>
</template>

<script setup>
import { AtSymbolIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
    email: String,
});
const link = `mailto:${props.email}`;
</script>
