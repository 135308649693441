<template>
    <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="show"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 dark:bg-gray-800 dark:ring-white/20"
            @click="cancelHide()"
        >
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <component
                            :is="icon"
                            class="size-6"
                            :class="{
                                'text-green-600 dark:text-green-600':
                                    toast.type === 'success',
                                'text-yellow-600 dark:text-yellow-600':
                                    toast.type === 'warning',
                                'text-red-600 dark:text-red-600':
                                    toast.type === 'danger',
                                'text-blue-600 dark:text-blue-600':
                                    toast.type === 'info',
                            }"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p
                            class="text-sm font-medium"
                            :class="{
                                'text-green-900 dark:text-green-200':
                                    toast.type === 'success',
                                'text-yellow-900 dark:text-yellow-200':
                                    toast.type === 'warning',
                                'text-red-900 dark:text-red-200':
                                    toast.type === 'danger',
                                'text-blue-900 dark:text-blue-200':
                                    toast.type === 'info',
                            }"
                        >
                            {{ toast.title }}
                        </p>
                        <p
                            class="mt-1 text-sm"
                            :class="{
                                'text-green-700 dark:text-green-400':
                                    toast.type === 'success',
                                'text-yellow-700 dark:text-yellow-500':
                                    toast.type === 'warning',
                                'text-red-700 dark:text-red-400':
                                    toast.type === 'danger',
                                'text-blue-700 dark:text-blue-400':
                                    toast.type === 'info',
                            }"
                        >
                            {{ toast.body }}
                        </p>
                    </div>
                    <div class="ml-4 flex flex-shrink-0">
                        <button
                            type="button"
                            @click="show = false"
                            class="inline-flex rounded-md bg-transparent text-gray-400 ring-offset-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:text-gray-500 dark:ring-offset-gray-800 dark:hover:text-gray-400 dark:focus:ring-gray-400"
                        >
                            <span class="sr-only">Close</span>
                            <XMarkIcon class="size-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>

            <!-- Show Timer -->
            <transition
                leave-active-class="transform ease-linear duration-[2.5s] transition-all"
                leave-from-class="w-full"
                leave-to-class="w-px"
            >
                <div
                    v-show="progress"
                    class="h-1"
                    :class="{
                        'bg-green-600 dark:bg-green-600':
                            toast.type === 'success',
                        'bg-yellow-600 dark:bg-yellow-600':
                            toast.type === 'warning',
                        'bg-red-600 dark:bg-red-600': toast.type === 'danger',
                        'bg-blue-600 dark:bg-blue-600': toast.type === 'info',
                    }"
                ></div>
            </transition>
        </div>
    </transition>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const show = ref(true);
const progress = ref(true);
let timeoutId;

const props = defineProps({
    toast: Object,
});

onMounted(() => {
    progress.value = false;
    timeoutId = setTimeout(() => {
        show.value = false;
    }, 2500);
});

const cancelHide = () => {
    progress.value = true;
    clearTimeout(timeoutId);
};

const icon = computed(() => {
    if (props.toast.type === "success") {
        return CheckCircleIcon;
    } else if (props.toast.type === "warning") {
        return ExclamationCircleIcon;
    } else if (props.toast.type === "danger") {
        return XCircleIcon;
    } else {
        return InformationCircleIcon;
    }
});
</script>
