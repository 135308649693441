<template>
    <li :data-tag="model[labelField]">
        <Badge :color="color" :size="size">
            <div class="inline-flex items-center gap-x-1">
                {{ model[labelField] }}
                <button
                    type="button"
                    class="group relative -mr-1 size-4 rounded-full"
                    @click.prevent="removeTag"
                    :class="{
                        'hover:bg-green-500/20': color === 'green',
                        'hover:bg-yellow-500/20': color === 'yellow',
                        'hover:bg-red-500/20': color === 'red',
                        'hover:bg-purple-500/20': color === 'purple',
                        'hover:bg-blue-500/20': color === 'blue',
                        'hover:bg-gray-500/20': color === 'gray',
                    }"
                >
                    <span class="sr-only">Remove</span>
                    <svg
                        viewBox="0 0 14 14"
                        class="size-3.5"
                        :class="{
                            'stroke-green-700/50 group-hover:stroke-green-700/75 dark:stroke-green-400/75 dark:group-hover:stroke-green-400/90':
                                color === 'green',
                            'stroke-yellow-800/50 group-hover:stroke-yellow-800/75 dark:stroke-yellow-500/75 dark:group-hover:stroke-yellow-500/90':
                                color === 'yellow',
                            'stroke-red-800/50 group-hover:stroke-red-800/75 dark:stroke-red-400/75 dark:group-hover:stroke-red-400/90':
                                color === 'red',
                            'stroke-purple-800/50 group-hover:stroke-purple-800/75 dark:stroke-purple-400/75 dark:group-hover:stroke-purple-400/90':
                                color === 'purple',
                            'stroke-blue-800/50 group-hover:stroke-blue-800/75 dark:stroke-blue-400/75 dark:group-hover:stroke-blue-400/90':
                                color === 'blue',
                            'stroke-gray-700/50 group-hover:stroke-gray-700/75 dark:stroke-gray-400/75 dark:group-hover:stroke-gray-400/90':
                                color === 'gray',
                        }"
                    >
                        <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span class="absolute -inset-1" />
                </button>
            </div>
        </Badge>
    </li>
</template>

<script setup>
import Badge from "@/Shared/Badges/Badge.vue";

const props = defineProps({
    size: {
        validator(value) {
            return ["md", "lg"].includes(value);
        },
        default: "md",
    },

    color: {
        validator(value) {
            return [
                "green",
                "yellow",
                "red",
                "blue",
                "purple",
                "gray",
            ].includes(value);
        },
        default: "blue",
    },

    model: {
        type: Object,
        required: true,
    },
    labelField: {
        type: String,
        default: "name",
    },
});

const emit = defineEmits(["remove"]);

const removeTag = () => {
    emit("remove", props.model);
};
</script>
