<template>
    <div>
        <FormInputLabel :field="field" :required="required">
            <slot />
        </FormInputLabel>

        <div class="relative mt-1">
            <textarea
                :rows="rows"
                :name="field"
                :id="field"
                class="block w-full rounded-md py-1.5 text-base outline outline-1 -outline-offset-1 focus:outline focus:outline-2 focus:-outline-offset-2 enabled:bg-white disabled:bg-gray-50 sm:text-sm/6 dark:enabled:bg-white/5 dark:disabled:bg-gray-800"
                :class="{
                    'text-gray-900 outline-gray-300 placeholder:text-gray-400 focus:outline-blue-600 focus:ring-blue-600 dark:text-gray-200 dark:outline-gray-500 dark:placeholder:text-gray-400 dark:focus:outline-blue-500 dark:focus:ring-blue-500':
                        !error,
                    'text-red-900 outline-red-300 placeholder:text-red-300 focus:outline-red-600 focus:ring-red-600 sm:pr-9 dark:text-red-300 dark:outline-red-700 dark:placeholder:text-red-500/50 dark:focus:outline-red-500 dark:focus:ring-red-500':
                        error,
                }"
                @input="$emit('update:modelValue', $event.target.value)"
                v-bind="$attrs"
                v-text="modelValue"
            />

            <FormErrorIcon :error="error" position="br" />
        </div>

        <div class="mt-1" v-if="errorField">
            <FormErrors v-if="error" :error="error" :field="field" />
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import FormErrorIcon from "@/Shared/Forms/Components/FormErrorIcon.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";

defineProps({
    field: {
        type: String,
        required: true,
    },
    error: String,
    modelValue: String,
    required: Boolean,
    rows: {
        type: Number,
        default: 4,
    },
    errorField: {
        type: Boolean,
        default: true,
    },
});
</script>
