<template>
    <dt
        class="sr-only"
        :class="{
            'sm:hidden': hideAt === 'sm',
            'md:hidden': hideAt === 'md',
            'lg:hidden': hideAt === 'lg',
            'xl:hidden': hideAt === 'xl',
        }"
        v-text="label"
    ></dt>
    <dd
        class="mt-1 truncate text-gray-500 dark:text-gray-400"
        :class="{
            'sm:hidden': hideAt === 'sm',
            'md:hidden': hideAt === 'md',
            'lg:hidden': hideAt === 'lg',
            'xl:hidden': hideAt === 'xl',
        }"
        v-bind="$attrs"
    >
        <slot />
    </dd>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
defineProps({
    hideAt: {
        validator(value) {
            return ["sm", "md", "lg", "xl", false].includes(value);
        },
        default: false,
    },
    label: String,
});
</script>
