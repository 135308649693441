<template>
    <MenuItem v-slot="{ active }">
        <component
            :is="isButton ? 'button' : 'Link'"
            :type="isButton ? 'button' : undefined"
            :href="isButton ? undefined : href"
            @click="onClick"
            class="group flex w-full items-center px-4 py-2 text-sm"
            :class="{
                'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-200':
                    active && color === 'gray',
                'bg-red-100 text-red-900 dark:bg-red-800 dark:text-red-200':
                    active && color === 'red',
                'text-gray-700 dark:text-gray-300': !active,
            }"
        >
            <component
                :is="icon"
                class="mr-3 size-5 text-gray-400 dark:text-gray-500"
                :class="{
                    ' group-hover:text-gray-500 dark:group-hover:text-gray-400':
                        color === 'gray',
                    ' group-hover:text-red-500 dark:group-hover:text-red-400':
                        color === 'red',
                }"
                aria-hidden="true"
            />
            <slot />
        </component>
    </MenuItem>
</template>

<script setup>
import { computed } from "vue";
import { MenuItem } from "@headlessui/vue";

const props = defineProps({
    icon: {
        type: Function,
        required: true,
    },
    color: {
        type: String,
        default: "gray",
        validator: (value) => ["gray", "red"].includes(value),
    },
    href: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["click"]);

const onClick = () => {
    emit("click");
};

const isButton = computed(() => props.href === "");
</script>
