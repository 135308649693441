<template>
    <Modal ref="modal" color="red">
        <template #icon>
            <ExclamationTriangleIcon
                class="size-6 text-red-600 dark:text-red-700"
                aria-hidden="true"
            />
        </template>
        <slot>Confirm Delete</slot>
        <template #description>
            Are you sure you want to delete
            <span class="font-semibold">{{ model.name }}</span
            >?
        </template>
        <template #buttons>
            <Button
                data-cy="delete-modal-confirm-button"
                color="red"
                @click="deleteModel()"
                class="w-full justify-center sm:ml-3 sm:w-auto sm:text-sm"
                >Delete</Button
            >
            <Button
                data-cy="delete-modal-cancel-button"
                color="white"
                @click="closeModal()"
                ref="cancelButtonRef"
                class="mt-3 w-full justify-center sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                >Cancel</Button
            >
        </template>
    </Modal>
</template>

<script setup>
import Button from "@/Shared/Buttons/Button.vue";
import Modal from "@/Shared/Modals/Modal.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { ref, inject } from "vue";
import { router } from "@inertiajs/vue3";

const model = inject("model");
const modal = ref(null);

const deleteModel = () => {
    router.delete(model.routes.delete, {
        replace: true,
        onStart: () => {
            closeModal();
        },
        onSuccess: (page) => {
            emit("deleteSuccess", page.props.toast ?? false);
        },
        onError: (error) => {
            // TODO: Temporary
            console.error(error);
        },
        onFinish: (visit) => {},
    });
};

const emit = defineEmits(["flash", "deleteSuccess"]);

const openModal = () => {
    modal.value.openModal();
};

const closeModal = () => {
    modal.value.closeModal();
};

defineExpose({
    openModal,
    closeModal,
});
</script>
