<template>
    <div
        class="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow dark:divide-white/10 dark:bg-gray-800 dark:shadow-white/30"
    >
        <template v-if="slots.header">
            <div class="px-4 py-5 text-gray-900 sm:px-6 dark:text-gray-200">
                <slot name="header" />
            </div>
        </template>
        <div class="px-4 py-5 sm:p-6">
            <slot />
        </div>
        <template v-if="slots.footer">
            <div class="px-4 py-4 text-gray-900 sm:px-6 dark:text-gray-200">
                <slot name="footer" />
            </div>
        </template>
    </div>
</template>

<script setup>
import { useSlots } from "vue";

const slots = useSlots();
</script>
