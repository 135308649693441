<template>
    <!-- List heading -->
    <div
        class="border-b border-gray-300 px-4 py-5 sm:px-6 dark:border-gray-500"
    >
        <slot />
    </div>
    <!-- End list heading -->
</template>

<script setup></script>
