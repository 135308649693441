<template>
    <div class="px-4 md:px-0">
        <form v-bind="$attrs">
            <slot />

            <div
                class="mt-8 border-t border-gray-100 pt-5 dark:border-gray-700"
            >
                <div class="flex justify-end">
                    <slot name="buttons" />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup></script>
