<template>
    <ListboxOption as="template" :value="option" v-slot="{ active, selected }">
        <li
            :class="[
                active
                    ? 'bg-blue-600 text-white outline-none dark:bg-blue-700 dark:text-gray-200'
                    : 'text-gray-900 dark:text-gray-200',
                'relative cursor-default select-none py-2 pl-3 pr-9',
            ]"
            :data-cy="`${config.field}-option-${index}`"
        >
            <slot>
                <span
                    :class="[
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate',
                    ]"
                >
                    {{ option[config.label] }}
                </span>
            </slot>

            <span
                v-if="selected"
                :class="[
                    active
                        ? 'text-white dark:text-gray-200 '
                        : 'text-blue-600 dark:text-blue-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                ]"
            >
                <CheckIcon class="size-5" aria-hidden="true" />
            </span>
        </li>
    </ListboxOption>
</template>

<script setup>
import { inject } from "vue";
import { ListboxOption } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/20/solid";

const props = defineProps({
    index: {
        type: [Number, String],
        required: true,
    },
    option: {
        type: Object,
        required: true,
    },
});

const config = inject("config");
</script>
