<template>
    <div>
        <span
            class="flex size-8 items-center justify-center rounded-full ring-8"
            :class="[ringClass ?? 'ring-white dark:ring-gray-800']"
            v-bind="$attrs"
        >
            <component
                :is="icon"
                class="size-5 text-white dark:text-gray-200"
                aria-hidden="true"
            />
        </span>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
    icon: {
        type: [Object, Function],
        required: true,
    },
    ringClass: {
        type: String,
        default: null,
    },
});
</script>
