<template>
    <button
        type="button"
        :title="label"
        :class="{
            'text-gray-900 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-200':
                current !== theme && !mobileMenu,
            'text-blue-500  dark:text-blue-500':
                current === theme && !mobileMenu,
            'text-gray-100 dark:text-gray-300': current !== theme && mobileMenu,
            'text-blue-300 dark:text-blue-400': current === theme && mobileMenu,
        }"
        @click="setTheme(theme)"
    >
        <div class="flex flex-col items-center justify-center space-y-2">
            <component
                :is="icon"
                aria-hidden="true"
                :class="{
                    'size-5': !mobileMenu,
                    'size-6': mobileMenu,
                }"
            />
            <span class="text-sm" :class="[!mobileMenu ? 'sr-only' : '']">{{
                label
            }}</span>
        </div>
    </button>
</template>

<script setup>
import { inject, computed } from "vue";
import {
    SunIcon,
    MoonIcon,
    ComputerDesktopIcon,
} from "@heroicons/vue/24/outline";
import {
    SunIcon as SunIconSolid,
    MoonIcon as MoonIconSolid,
    ComputerDesktopIcon as ComputerDesktopIconSolid,
} from "@heroicons/vue/24/solid";

const props = defineProps({
    theme: {
        type: String,
        required: true,
    },
    mobileMenu: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["onThemeChange"]);

const current = inject("current");

const label = computed(
    () => props.theme[0].toUpperCase() + props.theme.substring(1)
);

const icons = {
    dark: MoonIcon,
    light: SunIcon,
    system: ComputerDesktopIcon,
};

const currentIcons = {
    dark: MoonIconSolid,
    light: SunIconSolid,
    system: ComputerDesktopIconSolid,
};

const icon = computed(() => {
    if (current.value === props.theme) {
        return currentIcons[props.theme];
    }

    return icons[props.theme];
});

const setTheme = (theme) => {
    emit("onThemeChange", theme);
};
</script>
