<template>
    <BaseAvailabilityCell
        class="border border-gray-300 hover:border-gray-400 dark:border-gray-500 dark:hover:border-gray-400"
        :class="
            available
                ? 'bg-green-50 dark:bg-green-950'
                : 'bg-red-50 dark:bg-red-950'
        "
        :data-tippy-target="template"
    >
        <!-- Tippy tooltip template -->
        <Tippy :target="template" :extra="tippyConfig">
            <p>{{ timeslot.day_string }}</p>
            <p>{{ timeslot.range }}</p>
            <p>{{ available ? "Available" : "Unavailable" }}</p>
        </Tippy>
    </BaseAvailabilityCell>
</template>

<script setup>
import BaseAvailabilityCell from "./BaseAvailabilityCell.vue";
import { Tippy, TippyDirective } from "tippy.vue";

const props = defineProps({
    timeslot: Object,
    available: Boolean,
});

const vTippy = TippyDirective;

const tippyConfig = {
    theme: "branded-light",
    allowHTML: true,
    animation: "shift-away-extreme",
    delay: [1000, 250],
    trigger: "mouseenter focus click",
    role: "tooltip",
    placement: "top",
    onTrigger(instance, event) {
        if (event.type === "click") {
            instance.show();
            setTimeout(() => instance.hide(), 2000);
        }
    },
};

const template = `timeslot-${props.timeslot.id}-template`;
</script>
