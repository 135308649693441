<template>
    <div>
        <fieldset>
            <legend
                class="block text-sm/6 font-medium text-gray-900 dark:text-gray-200"
            >
                <slot>Date Filter</slot>
            </legend>
            <div class="mt-1 -space-y-px rounded-md">
                <div class="flex -space-x-px">
                    <BaseDateInput
                        field="start_date"
                        v-model="dates.start"
                        @update:modelValue="updateDates($event, 'start')"
                        class="rounded-none rounded-l-md"
                        placeholder="MM/DD/YYYY"
                    />
                    <div
                        class="pointer-events-none border-b border-t border-gray-300 bg-gray-100 p-1 pt-2 text-gray-400 dark:border-gray-500 dark:bg-gray-800 dark:text-gray-300"
                    >
                        -
                    </div>
                    <BaseDateInput
                        field="end_date"
                        v-model="dates.end"
                        @update:modelValue="updateDates($event, 'end')"
                        class="rounded-none rounded-r-md"
                        placeholder="MM/DD/YYYY"
                    />
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script setup>
import BaseDateInput from "@/Shared/Forms/Components/BaseDateInput.vue";
import dayjs from "dayjs";
import { reactive } from "vue";

const model = defineModel();

const emit = defineEmits(["update:modelValue"]);

const dates = reactive({
    start: model.value.start,
    end: model.value.end,
});

const updateDates = (value, field) => {
    dates[field] = value;

    if (field === "start" && dayjs(dates.start).isAfter(dayjs(dates.end))) {
        dates.end = dates.start;
    } else if (
        field === "end" &&
        dayjs(dates.end).isBefore(dayjs(dates.start))
    ) {
        dates.start = dates.end;
    }

    emitChange();
};

const emitChange = () => {
    emit("update:modelValue", { start: dates.start, end: dates.end });
};
</script>
