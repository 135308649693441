<template>
    <InputWrapper :error-field="errorField">
        <FormInputLabel :field="field" :required="required">
            <slot />
        </FormInputLabel>

        <BaseDateInput
            v-model="model"
            :field="field"
            :error="error"
            :prepend="prepend"
            :required="required"
            placeholder="MM/DD/YYYY"
            :config="config"
            :in-popover="inPopover"
            v-bind="$attrs"
        />

        <div class="h-5">
            <FormErrors v-if="error" :error="error" :field="field" />
        </div>
    </InputWrapper>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>
<script setup>
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import FormErrors from "@/Shared/Forms/Components/FormErrors.vue";
import BaseDateInput from "@/Shared/Forms/Components/BaseDateInput.vue";
import InputWrapper from "@/Shared/Forms/Components/InputWrapper.vue";

const model = defineModel();

const props = defineProps({
    field: String,
    error: String,
    prepend: {
        type: String,
        default: null,
    },
    required: Boolean,
    config: {
        type: Object,
        default: () => ({}),
    },
    errorField: {
        type: Boolean,
        default: true,
    },
    inPopover: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue"]);
</script>
