<template>
    <Disclosure
        as="nav"
        class="bg-blue-800 dark:bg-blue-900 print:hidden"
        v-slot="{ open, close }"
    >
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center justify-between">
                <div class="flex items-center">
                    <div class="flex-shrink-0">
                        <Link :href="links.home">
                            <BrandSvgNav id="nav-brand" />
                        </Link>
                    </div>
                    <div class="hidden lg:block">
                        <div class="ml-10 flex items-baseline space-x-4">
                            <template v-for="menu in navigation">
                                <NavMenu
                                    v-if="menu.hasOwnProperty('items')"
                                    :menu="menu"
                                />
                                <NavMenuButton
                                    v-else
                                    :href="getRoute(menu.key, links)"
                                    >{{ menu.label }}</NavMenuButton
                                >
                            </template>
                        </div>
                    </div>
                </div>
                <div class="hidden lg:block">
                    <div class="ml-4 flex items-center md:ml-6">
                        <!-- Notifications -->
                        <!-- <button type="button"
                            class="bg-blue-800 p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                            <span class="sr-only">View notifications</span>
                            <BellIcon class="h-6 w-6" aria-hidden="true" />
                        </button> -->

                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative ml-3">
                            <div>
                                <MenuButton
                                    class="flex max-w-xs items-center rounded-full bg-blue-800 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-blue-800 dark:bg-blue-900 dark:focus:ring-gray-400 dark:focus:ring-offset-blue-900"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <!-- <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" /> -->
                                    <div class="group flex items-center">
                                        <div class="mr-3">
                                            <p
                                                class="text-base/5 font-medium text-blue-100 group-hover:text-white dark:text-blue-300 dark:group-hover:text-gray-200"
                                            >
                                                {{ user.name }}
                                            </p>
                                        </div>
                                        <span
                                            class="hover:shadow-solid inline-block size-8 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-300"
                                        >
                                            <template v-if="user.avatarUrl">
                                                <img
                                                    :src="user.avatarUrl"
                                                    class="group-hover:opacity-90"
                                                />
                                            </template>
                                            <GenericUser
                                                v-else
                                                class="text-gray-400 group-hover:text-gray-300 dark:text-gray-500 dark:group-hover:text-gray-400"
                                            />
                                        </span>
                                    </div>
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none dark:divide-white/10 dark:bg-gray-800 dark:ring-white/20"
                                >
                                    <section>
                                        <DropdownItem
                                            v-for="item in userNavigation"
                                            :href="getRoute(item.key, links)"
                                            >{{ item.label }}</DropdownItem
                                        >
                                    </section>
                                    <MenuItem v-slot="{ active, close }">
                                        <div
                                            :class="[
                                                active
                                                    ? 'bg-blue-100 text-gray-900 dark:bg-blue-700 dark:text-gray-200'
                                                    : 'text-gray-700 dark:text-gray-300',
                                            ]"
                                        >
                                            <ThemeToggleMenuItem />
                                        </div>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <Link
                                            :href="getRoute('logout', links)"
                                            method="post"
                                            as="button"
                                            :class="[
                                                active
                                                    ? 'bg-blue-100 text-gray-900 dark:bg-blue-700 dark:text-gray-200'
                                                    : 'text-gray-700 dark:text-gray-300',
                                                'block w-full px-4 py-2 text-left text-sm',
                                            ]"
                                        >
                                            Logout</Link
                                        >
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <div class="-mr-2 flex lg:hidden">
                    <!-- Mobile menu button -->
                    <DisclosureButton
                        class="inline-flex items-center justify-center rounded-md bg-blue-800 p-2 text-blue-100 hover:bg-blue-500/75 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-blue-800 dark:bg-blue-900 dark:text-blue-300 dark:hover:bg-blue-700/75 dark:hover:text-gray-200 dark:focus:ring-gray-400 dark:focus:ring-offset-blue-900"
                    >
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon
                            v-if="!open"
                            class="block size-6"
                            aria-hidden="true"
                        />
                        <XMarkIcon
                            v-else
                            class="block size-6"
                            aria-hidden="true"
                        />
                    </DisclosureButton>
                </div>
            </div>
        </div>

        <DisclosurePanel class="lg:hidden">
            <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                <div class="space-y-4">
                    <template v-for="menu in navigation">
                        <Dropdown v-if="menu.hasOwnProperty('items')">
                            {{ menu.label }}
                            <template #items>
                                <DropdownItemGroup v-for="group in menu.items">
                                    <Link
                                        v-for="item in group"
                                        @click.capture="close"
                                        :href="getRoute(item.key, links)"
                                        class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                                    >
                                        {{ item.label }}
                                    </Link>
                                </DropdownItemGroup>
                            </template>
                        </Dropdown>
                        <DisclosureButton
                            v-else
                            :as="NavMenuButton"
                            :href="getRoute(menu.key, links)"
                            v-text="menu.label"
                        ></DisclosureButton>
                    </template>
                </div>
            </div>
            <div class="border-t border-blue-700 pb-3 pt-4">
                <div class="flex items-center px-5">
                    <div class="flex-shrink-0">
                        <!-- Avatar -->
                        <span
                            class="hover:shadow-solid inline-block size-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-300"
                        >
                            <GenericUser
                                class="text-gray-400 group-hover:text-gray-300 dark:text-gray-500 dark:group-hover:text-gray-400"
                            />
                        </span>
                    </div>
                    <div class="ml-3">
                        <div
                            class="text-base font-semibold text-gray-100 dark:text-gray-300"
                        >
                            {{ user.name }}
                        </div>
                        <div
                            class="text-sm font-medium text-blue-100 dark:text-blue-300"
                        >
                            {{ user.email }}
                        </div>
                    </div>
                    <!-- <button type="button"
                        class="ml-auto bg-blue-800 flex-shrink-0 p-1 border-2 border-transparent rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                        <span class="sr-only">View notifications</span>
                        <BellIcon class="h-6 w-6" aria-hidden="true" />
                    </button> -->
                </div>
                <div class="mt-3 space-y-1 px-2">
                    <Link
                        v-for="item in userNavigation"
                        :key="item.label"
                        :href="getRoute(item.key, links)"
                        class="block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-blue-500/75 dark:text-gray-300 dark:hover:bg-blue-700/75"
                        @click="close"
                    >
                        {{ item.label }}</Link
                    >

                    <div class="text-gray-100 dark:text-gray-300">
                        <ThemeToggleMenuItem mobile-menu @click="close" />
                    </div>

                    <Link
                        :href="getRoute('logout', links)"
                        method="post"
                        as="button"
                        class="block w-full rounded-md px-3 py-2 text-left text-base font-medium text-gray-100 hover:bg-blue-500/75 dark:text-gray-300 dark:hover:bg-blue-700/75"
                    >
                        Logout</Link
                    >
                </div>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup>
import { provide, ref } from "vue";
import { useCurrentUser } from "@/Composables/useCurrentUser";
import { menus, getRoute } from "@/Modules/navigation";
import BrandSvgNav from "@/Shared/Svg/BrandSvgNav.vue";
import NavMenu from "@/Shared/Nav/Menus/NavMenu.vue";
import NavMenuButton from "@/Shared/Nav/Menus/NavMenuButton.vue";
import Dropdown from "../Dropdown.vue";
import DropdownItemGroup from "../DropdownItemGroup.vue";
import DropdownItem from "../DropdownItem.vue";
import ThemeToggleMenuItem from "../ThemeToggleMenuItem.vue";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/vue";
import { BellIcon, Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import GenericUser from "@/Shared/Svg/GenericUser.vue";

const props = defineProps({
    links: Object,
});

const isOpen = ref(false);

provide("links", props.links);

const user = useCurrentUser();
const { navigation, userNavigation } = menus(user);
</script>
