<template>
    <InputWrapper :error-field="errorField">
        <div :class="{ flex: showLock }">
            <FormInputLabel :field="field">
                <slot />
            </FormInputLabel>
            <template v-if="showLock">
                <span :title="lockText">
                    <LockClosedIcon
                        class="ml-2 size-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </template>
        </div>
        <div class="flex items-center">
            <div class="w-full">
                <BaseInput
                    v-model="value"
                    readonly
                    disabled
                    :field="field"
                    :prepend="prepend"
                    :append="append"
                    class="cursor-default"
                />
            </div>
            <slot name="menu" />
        </div>
    </InputWrapper>
</template>

<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { ref } from "vue";
import FormInputLabel from "@/Shared/Forms/Components/FormInputLabel.vue";
import { LockClosedIcon } from "@heroicons/vue/20/solid";
import BaseInput from "@/Shared/Forms/Components/BaseInput.vue";
import InputWrapper from "@/Shared/Forms/Components/InputWrapper.vue";

const props = defineProps({
    field: String,
    prepend: {
        type: [String, Boolean],
        default: null,
    },
    append: {
        type: [String, Boolean],
        default: null,
    },
    value: String,
    showLock: {
        type: Boolean,
        default: false,
    },
    lockText: {
        type: String,
        default: "This field is locked",
    },
    errorField: {
        type: Boolean,
        default: true,
    },
});

const value = ref(props.value);
</script>
